@import "~assets/css/toast.scss";
@import "~assets/fonts/fonts.css";
@import "~react-toastify/dist/ReactToastify.min.css";
@import "~assets/css/variables.scss";

@font-face {
   font-family: "Avenir Next";
   src: url("~assets/fonts/AvenirNextLTPro-Demi.otf");
   /* IE9 Compat Modes */
   src: url("~assets/fonts/AvenirNextLTPro-Demi.otf") format("embedded-opentype"),
      /* IE6-IE8 */
      url("~assets/fonts/AvenirNextLTPro-Demi.otf") format("woff2"),
      /* Super Modern Browsers */
      url("~assets/fonts/AvenirNextLTPro-Demi.otf") format("woff"),
      /* Pretty Modern Browsers */
      url("~assets/fonts/AvenirNextLTPro-Demi.otf") format("truetype"),
      /* Safari, Android, iOS */
      url("~assets/fonts/AvenirNextLTPro-Demi.otf") format("svg");
   /* Legacy iOS */
}

@font-face {
   font-family: "Avenir Next Bold";
   src: url("~assets/fonts/AvenirNextLTPro-Bold.otf");
}

@font-face {
   font-family: "Lato ExtraBold";
   src: url("~assets/fonts/Lato/Lato-ExtraBold.ttf");
}

@font-face {
   font-family: "Lato Bold";
   src: url("~assets/fonts/Lato/Lato-Bold.ttf");
}

@font-face {
   font-family: "Avenir Next regular";
   src: url("~assets/fonts/AvenirNextLTPro-Regular.otf");
}

@font-face {
   font-family: "Avenir Next Regular";
   src: url("~assets/fonts/AvenirNextLTPro-Regular.otf");
}


body {
   background: #f4f7f7;
   // display: flex;
   margin: 0;
   // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
   //    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
   //    "Helvetica Neue", "Avenir Next", sans-serif;
   font-family: "Roboto";
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   scrollbar-color: rgba(119, 119, 119, 0.3) rgb(226, 226, 226);
   scrollbar-width: thin;
   // scrollbar-gutter: stable;
   font-size: 14px;
   // overflow-x: hidden;

   &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: transparent;
   }

   &::-webkit-scrollbar {
      width: 5px;
      background-color: transparent;
   }

   &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      box-shadow: inset 0 0 6px rgba(119, 119, 119, 0.3);
      -webkit-box-shadow: inset 0 0 6px rgba(119, 119, 119, 0.3);
      background-color: rgb(226, 226, 226);
   }

   top: 0 !important;
}

html {
   // overflow: hidden;
   height: 100%;
}

#root {
   overflow-x: hidden;
}

code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
}

#toasts_new {
   position: relative;
   z-index: 10000;
}

.content__left {
   flex: 1 1;
   max-width: 480px;
   box-sizing: border-box;
   border-right: 1px solid #e7e9e9;
   padding-right: 24px;
   margin-right: 24px;
}

.content__right {
   flex: 1 1;
   max-width: 624px;
   box-sizing: border-box;
}

@media screen and (max-width: 720px) {
   .itemWrapper-f {
      .itemWrapper {
         border: none !important;
         box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08) !important;
      }
   }

   .itemWrapper-n {
      .itemWrapper {
         box-shadow: none !important;
         border: solid 1px #dfe5eb !important;
      }
   }
}

.verify-email-alert {
   position: sticky;
   background-color: #7cb7401a;
   color: #7cb740;
   padding: 16px;
}

.custom-popover {
   .MuiPaper-root {
      box-shadow: none;
      background-color: inherit;
   }
}

.d {
   display: flex;
   width: 100%;
}

.d-a {
   display: flex;
   width: 100%;
   align-items: center;
}

.d-a-j {
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: space-between;
   flex-wrap: wrap;
   gap: 2px;
}

.pagePagination {
   display: flex;
   justify-content: center;
   gap: 8px;

   .paginationItem {
      background: #ffffff;
      /* grey scale/10 */
      border: 1px solid #e7e9e9;
      width: 32px;
      height: 32px;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      span {
         display: flex;
      }
   }

   .paginationDotes {
      display: flex;
      align-items: center;
   }

   .paginationItem__active {
      background: #24554e;

      border: 1px solid #153833;
      border-radius: 8px;
   }
}

.community {
   width: 100%;
   padding: 0px;
   background: #F4F7F7;
   height: 100vh;
   @media all and (max-width: 1024px) {
      height: 100dvh;
   }
   .header__type__first {
      height: 72px;
      max-height: 72px;
   }
   overflow: hidden;
   .adminContent:not(.adminContentAlt) {
      background: inherit;
      height: 100% !important;
   }
   &__bottom {
      display: flex;
      height: 100%;
      background: #FAFCFC;
      max-height: calc(100vh - 149px);
      @media all and (max-width: 1024px) {
         max-height: calc(100vh - 296px);
         @media all and (max-width: 1024px) {
            max-height: calc(100dvh - 296px);
         }
      }
   }
   &__bottom__withoutmenu {
      max-height: calc(100vh - 72px);
      @media all and (max-width: 1024px) {
         max-height: calc(100dvh - 72px);
      }
   }
   @media all and (max-width: 1024px) {
      min-height: 100vh;
      &__bottom {
         position: relative;
      }
   }
}

.communityWithoutSidebar {
   @media all and (max-width: 1024px) {
      .community__sidebar, .mobileHeader {
         display: none;
      }
   }
}

.community__view {
   display: flex;
   flex-direction: column;
   height: auto;
   padding: 34px;
   grid-gap: 32px;
   gap: 32px;
   box-sizing: border-box;
   width: 100%;
   scrollbar-color: rgba(119, 119, 119, 0.3) #e2e2e2;
   scrollbar-width: thin;
   overflow-y: auto;
   overflow-x: hidden;
   @media all and (max-width: 1024px) {
      padding: 16px !important;
      background: #FFFFFF !important;
   }
}

.blockedIpWrapper {
   height: 100vh;
   width: 100%;
   display: flex;
   flex-direction: column;
   gap: 24px;
   justify-content: center;
   align-items: center;
   background-color: #fff;
}

.blockedIpWrapper__text {
   font-family: 'Montserrat', sans-serif;
   line-height: 42px;
   text-align: center;
   padding: 0px 50px;
   font-size: 36px;
}

.scroll {
   scrollbar-color: rgba(119, 119, 119, 0.3) rgb(226, 226, 226);
   scrollbar-width: thin;
   overflow-y: auto;

   &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: transparent;
   }

   &::-webkit-scrollbar {
      width: 5px;
      height: 2px;
      background-color: transparent;
   }

   &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      box-shadow: inset 0 0 6px rgba(119, 119, 119, 0.3);
      -webkit-box-shadow: inset 0 0 6px rgba(119, 119, 119, 0.3);
      background-color: rgb(226, 226, 226);
   }
}


body>iframe[style*='2147483647'] {
   display: none;
}

.grecaptcha-badge {
   display: none !important;
}

* {
   box-sizing: border-box;
}

a {
   text-decoration: none;
}

h4 {
   font-weight: 400 !important;
}


.circleRollButton {
   display: none !important;
}

iframe.i-embedded-form {
   background: #36796F;
}

.ql-size-14px {
   font-size: 14px;
}

.ql-size-16px {
   font-size: 16px;
}

.ql-size-18px {
   font-size: 18px;
}

.ql-size-24px {
   font-size: 24px;
}

.ql-size-36px {
   font-size: 36px;
}

.ql-size-48px {
   font-size: 48px;
}

.ql-size-48px {
   font-size: px;
}

.ql-size-54px {
   font-size: 54px;
}

.ql-size-4px {
   font-size: 4px;
}

.ql-size-6px {
   font-size: 6px;
}

.ql-size-8px {
   font-size: 8px;
}

.ql-size-10px {
   font-size: 10px;
}

.ql-size-12px {
   font-size: 12px;
}

.text p {
   margin-bottom: 0;
}

p,
.offers__footer__top p {
   margin-bottom: 0;
}

#google_translate_element {
   display: none;
}

.skiptranslate {
   display: none;
}

.VIpgJd-ZVi9od-aZ2wEe-wOHMyf {
   display: none !important;
}

#goog-gt-tt {
   display: none !important;
}

div h1,
div h2 {
   font-weight: revert;
   margin-bottom: 0;
}

.ql-bubble .ql-picker.ql-expanded .ql-picker-options {
   max-height: 130px;
   scrollbar-width: thin;
   overflow-y: auto;

}

.ql-bubble .ql-picker.ql-expanded .ql-picker-options ::-webkit-scrollbar-track {
   border-radius: 10px;
   background-color: transparent;
}

.ql-bubble .ql-picker.ql-expanded .ql-picker-options ::-webkit-scrollbar {
   width: 5px;
   height: 5px;
   background-color: transparent;
}

.ql-bubble .ql-picker.ql-expanded .ql-picker-options ::-webkit-scrollbar-thumb {
   border-radius: 3px;
   box-shadow: inset 0 0 6px rgba(119, 119, 119, 0.3);
   -webkit-box-shadow: inset 0 0 6px rgba(119, 119, 119, 0.3);
   background-color: rgb(226, 226, 226);
}

#google_translate_element {
   display: none;
}

.ql-align-right {
   text-align: right;
}

.ql-align-center {
   text-align: center;
}

.ql-align-justify {
   text-align: justify;
}

#goog-gt-tt {
   display: none !important;
}